import axios from "axios";
import { getFacilityPath, matchStatusAndThrowError } from "client/services/api";
import { HttpClient } from "client/services/http-client";
import { base_path } from "client/services/paths";
import { SessionStorageKeys } from "common/enums";
import { DateTimeConfig, TimeFormats } from "common/types/date-time-format";
import moment from "moment";
import { countries, zones } from "moment-timezone/data/meta/latest.json";

export enum TimeFormatsWithoutSec {
  "hh:mm" = "hh:mm",
  "HH:mm" = "HH:mm"
}

export function getCountriesList() {
  return Object.keys(countries).map(countryCode => ({
    name: countries[countryCode].name,
    value: countries[countryCode].name
  }));
}

export const getZones = (country?: string) => {
  let zonesData;
  if (country) {
    const selectedCountryCode = Object.keys(countries).find(
      countryCode => countries[countryCode].name === country
    );
    if (!selectedCountryCode) return [];
    zonesData = countries[selectedCountryCode].zones;
  } else {
    zonesData = Object.keys(zones);
  }
  return zonesData.map((zone: string) => ({
    name: `UTC ${moment.tz(zone).format("Z")} ${zone
      .substring(zone.lastIndexOf("/") + 1)
      .replace(/_/g, " ")}`,
    value: zone
  }));
};

export const getDateTimeConfig = async (): Promise<{
  dateTimeSettings: DateTimeConfig;
}> => {
  let result;
  try {
    const url = base_path + getFacilityPath() + `/date-time-setting`;
    result = await HttpClient.get(url, {});
    result = result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Date & Time Settings");
    } else throw e;
  }
  return result;
};
export const setDateTimeconfig = async (data: DateTimeConfig) => {
  try {
    const url = base_path + getFacilityPath() + `/date-time-setting`;
    await HttpClient.post(url, data);
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Date & Time Settings");
    } else throw e;
  }
};

export const convertTimeFormat = (timeFormat: TimeFormats) => {
  switch (timeFormat) {
    case TimeFormats["HH:mm"]:
    case TimeFormats["HH:mm:ss"]:
      return TimeFormatsWithoutSec["HH:mm"];
    case TimeFormats["hh:mm"]:
    case TimeFormats["hh:mm:ss"]:
    default:
      return TimeFormatsWithoutSec["hh:mm"];
  }
};
